export default [
  {
    name: 'Craig',
    quote: `It's no exaggeration to say that I
  wouldn't currently have a job in development without this site. So thanks for
  ruining my life, Tania.`,
  },
  {
    name: 'Lori',
    quote: `Not to get too dramatic, but I find your site to be an unspeakably beautiful
  lifeboat in an overwhelming sea of technical jargon and shite.`,
  },
  {
    name: 'Evan',
    quote: `You taught me more than any class could have and it took me a fraction of the time because of how clearly you write and teach.`,
  },
  {
    name: 'Zuzana',
    quote: `I keep coming back to your website and I appreciate it more and more every time. The way you explain things just make sense!`,
  },
  {
    name: 'P.P.',
    quote: `You are the best tutor I have ever seen in my life. This tutorial is an excellent example of simplicity, clear explanations and detailed examples. I love every bit of your work. Never stop teaching, please.`,
  },
  {
    name: 'Antony',
    quote: 'Just wanted to thank you for your awesome tutorials. Glad you found your niche, teaching valuable skills to so many folks out there, instead of preparing fine meals for a select few.'
  }
]
